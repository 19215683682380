import { getCellStyle } from "../../Utils/DecideColor";
import { Box } from "@mui/material";

export const userColoumns = () => {
  const columns = [
    {
      accessorKey: 'userId',
      header: 'User ID',
    },
    {
      accessorKey: 'userName',
      header: 'User Name',
    },
    {
      accessorKey: 'fullName',
      header: 'Full Name',
    },
    {
      accessorKey: 'role',
      header: 'User Role',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        let displayValue;
        switch (value) {
          case 'Active':
            displayValue = 'Active';
            break;
          case 'Inactive':
            displayValue = 'Inactive';
            break;
          case 'Unverified':
            displayValue = 'Unverified';
            break;
          default:
            displayValue = '-';
            break;
        }
        return (
          <Box
            sx={(theme) => (value ? {
              ...getCellStyle(value, "User"),
            } : {})}
          >
            {displayValue}
          </Box>
        );
      }
    },
  ];

  return columns;
};

export function extractedDataForUser(data) {
  return data.map(item => {
    const domainCharacteristic = item.role
    let domainValue = '-';
    if (domainCharacteristic) {
      const value = domainCharacteristic;
      domainValue = !value ? "" :
        value === 'operatorOperationUser' ? 'EV Operations User' :
          value === 'operatorBusinessUser' ? 'EV Business User' :
            value === 'providerOperationUser' ? 'Provider Operations User' :
              value === 'telcoOperationUser' ? 'Telco Operations User' :
                value === 'telcoBusinessUser' ? 'Telco Business User' :
                  value === 'genericOperationUser' ? 'Operations User' :
                    value === 'genericBusinessUser' ? 'Business User' :
                      value === 'ISPOperationUser' ? 'ISP Operations User' :
                        value === 'systemUser' ? 'System User' :
                          value === 'telcoAdmin' ? 'Telco Admin' :
                            value === 'operatorAdmin' ? 'Operator Admin' :
                              value === 'genericAdmin' ? 'Generic Admin' :
                                value === 'partnerAdmin' ? 'Partner Admin' :
                                  value === 'ISPOperatorAdmin' ? 'ISP Operator Admin' :
                                    value === 'ISPPartnerAdmin' ? 'ISP Partner Admin' :
                                      value === 'smartMeterAdmin' ? 'Smart Meter Admin' :
                                        'ISP Business User';
    }

    return {
      userId: item.userDetails?.userId || '-',
      userName: item.userDetails?.userName || '-',
      fullName: `${item.profile?.firstName || ''} ${item.profile?.lastName || ''}`,
      role: domainValue || '-',
      email: item.email || '-',
      phone: item.dialCode && item.phone ? `${item.dialCode} ${item.phone}` : '-',
      status: item.status,
    };
  });
}
