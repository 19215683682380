import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import ProfileImage from '../../assets/images/USERPROFILEIMAGE.png';
import { viewDoc } from '../../slices/customer/documentview/thunk';
import { useSelector } from 'react-redux';

export default function CustomerProfileCard({ customerData, dispatch, customerId, setLoading }) {
    const [profileData, setProfileData] = useState(false)
    useEffect(() => {
        let partnerOrCustomer = "Customer"
        dispatch(viewDoc(setLoading, customerId, "logo", partnerOrCustomer, setProfileData));
    }, [dispatch])
    let base64ImageData = []
    let profile = useSelector((state) => state.ViewDocument.documentView)

    base64ImageData = profile?.filePath

    return (
        <Card className="shadow-sm flex-fill card-profile">
            <div className="svg-container">
                <svg className="svg-wave1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#e5f1f5" fillOpacity="1" d="M0,160 C320,240 720,50 1440,100 L1440,320 L0,320 Z"></path>
                </svg>
                <svg className="svg-wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#ffffff" fillOpacity="1" d="M0,240 C480,320 960,200 1440,240 L1440,320 L0,320 Z"></path>
                </svg>
            </div>
            <CardBody className="card-body-custom">
                <div className="avatarr mb-3">
                    <img
                        src={profileData ? base64ImageData :ProfileImage}
                        alt="Profile"
                        className="rounded-circle"
                        width={100}
                    />
                </div>
                <CardTitle tag="h5">{customerData?.customerInfo?.firstName} {customerData?.customerInfo?.lastName}</CardTitle>
                <div className="row p-4">
                    <div className="col-12 col-md-6 text-center text-md-start mb-3 mb-md-0">
                        <CardText className="title-disable-color-edit-offer">Contact Number</CardText>
                        <CardText className="font-weight-bold fw-bold">{customerData?.customerInfo?.dialCode}-{customerData?.customerInfo?.mobile}</CardText>
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-end">
                        <CardText className="title-disable-color-edit-offer">Account Number</CardText>
                        <CardText className="font-weight-bold fw-bold">{customerData?.customerInfo?.customerId}</CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}